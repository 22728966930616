<template>
    <table class="col-12">
        <thead>
            <tr>
            <th class="width-10">Id</th>
            <th>Наименование</th>
            <th>Отчёты</th>
            <th>Машины</th>
            <th>Примечание</th>
            <th></th>
            </tr>
        </thead>
        <tbody>
            <GuidesElement 
                v-for="company in companyList" 
                :key="company.id"
                :id="company.id"
                :name="company.name"
                :autos="company.autos"
                :reports="company.reports"
                :comment="company.comment"
            />
        </tbody>
    </table>
</template>
<script>
    import GuidesElement from "@/components/Guides/Agents/GuidesElement.vue";

    export default {
        components: { GuidesElement },
        computed: {
            companyList () {
                return this.$store.state.companies
            }
        },
        mounted() {
            this.$store.dispatch("fetchCompanies");
        }
    }
</script>