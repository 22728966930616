<template>
    <tr>
        <td>{{ id }}</td>
        <td>{{ name }}</td>
        <td>{{ reports }}</td>
        <td>{{ autos }}</td>
        <td>{{ comment }}</td>
        <td class="right"><span class="icon-more-vertical"></span></td>
    </tr>
</template>

<script>
    export default {
        props: ['id', 'autos', 'comment', 'reports', 'name'],
    }
</script>